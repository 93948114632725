const appConfig = {
    APIURL: 'https://stok.marudermcosmetics.com/api/',
    //APIURL: 'http://localhost:8888/maruderm/',
    apiPrefix: '/',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig